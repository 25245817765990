body {
  margin: 0;
  padding-top: 50px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* background-image: url('/public/background-3.jpg'); */
  /* background-size: cover; */

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#main {
  margin-top: 20px;
  min-height: 90vh;
}


footer {
  margin-top: 15px;
  padding: 15px 0;
  /* position: static;
  bottom: 0;
  width: 100%;
  padding: 4px 15px;
  background-color: #222;
  font-size: 0.8rem;
  text-transform: uppercase;
  z-index: 1300; */
}

footer span {
  opacity: 0.8;
  cursor: default;
}

footer span:hover {
  opacity: 0.6;
  cursor: default;
}


/* audio::-webkit-media-controls-panel {
  background-color: #761010;
  color: #000;
} */

@keyframes showNav {
  from {opacity: 0;}
  to {opacity: 1;}
}